/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
   // Use this variable to set up the common and page specific functions. If you
   // rename this variable, you will also need to rename the namespace below.
   var Sage = {
      // All pages
      'common': {
         init: function () {
            // JavaScript to be fired on all pages


            if($('.ie, .edge').length){

            $('.poly-fill').each(function(){

               var image = $(this).attr('src');
            $(this).attr('style', 'background-image:url(' + image + '); background-size: cover; background-position: 50%; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/sanctuary-wp/dist/img/clear.png');

            });
            }




            $('.menu-toggle').click(function () {

               $(this).toggleClass('active');
               $('.nav-container').toggleClass('active');
               $('body').toggleClass('menu-open');
               return false;

            });

            $('.nav-overlay').click(function () {

               $('.menu-toggle').removeClass('active');
               $('.nav-container').removeClass('active');
               $('body').removeClass('menu-open');
               return false;

            });
            $('.cta-rsvp, .cta-footer .btn').click(function () {

               $('.modal').addClass('active');
               $('body').addClass('modal-open');
               return false;

            });

            $('.modal .close').click(function () {

               $('.modal').removeClass('active');
               $('body').removeClass('modal-open');
               return false;

            });


            $(document).on("scroll", function (e) {

               var windowfull = $.windowHeight();
               var windowHeight = $(window).height(),
                  remaining = windowfull - windowHeight;

               if (windowfull == windowHeight) {

                  console.log('scrolled');

                  $('body').removeClass('ui-minimized');
               } else {
                  console.log('scrolled');

                  $('body').addClass('ui-minimized');


               }


            });

         },
         finalize: function () {
            // JavaScript to be fired on all pages, after page specific JS is fired



         }
      }, // Home page
      'home': {
         init: function () {
            // JavaScript to be fired on the home page

            $('.hero-slider .slider').slick({
               //               lazyLoad: 'ondemand',
               slidesToShow: 1,
               //               dots: true,
               infinite: true,
               speed: 500,
               fade: true,
               cssEase: 'linear',
               adaptiveHeight: true,
               autoplay: true,
               autoplaySpeed: 10000,
            });

         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS


         }
      }, // Gallery page
      'gallery': {
         init: function () {
            // JavaScript to be fired on the home page

            $('.gallery-mfp').magnificPopup({
               type: 'image',
               mainClass: 'mfp-fade',
               removalDelay: 300,
               gallery: {
                  enabled: true
               }

            });

         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS


         }
      }, // Studios page
      'studios': {
         init: function () {
            // JavaScript to be fired on the home page

               $( '.over' ).mousemove( function(e){

                  var parentOffset = $(this).parents('.box').offset();
                  var relX = e.pageX - parentOffset.left;
                  var relY = e.pageY - parentOffset.top;

                  console.log( "pageX: " + relX + ", pageY: " + relY );

                  $(this).css("transform-origin", "" + relX + "px " + relY + "px 0");


               });


         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS


         }
      },
      'single_studios': {
         init: function () {
            // JavaScript to be fired on the home page

            $(document).ready(function(){

               $('.gear-list li:first-child a, .mobile-toggle:first-of-type').addClass('active');

            })

            $('.gear-list a, .mobile-toggle').click(function(){

               var target = $(this).attr('href');

               if($(window).width() < 768){

               $(target).slideDown(500);
               $('.tab').not(target).slideUp(500);

               var headerHeight = $('.main-header').outerHeight();

               $('.mobile-toggle').not(this).removeClass('active');


               } else {

               $(target).fadeIn(500);
               $('.tab').not(target).fadeOut(500);
               $('.gear-list a').not(this).removeClass('active');

               }

               $(this).addClass('active');
               return false;
            });


         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS


         }
      },
      'location': {
         init: function () {
            // JavaScript to be fired on the about us page
         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS


            $('.zoom-tip').click(function(){

               $(this).parents('.map-zoom').addClass('zoom');

               return false;

            });
            $(window).load(function () {

               setTimeout(function () {
                  $('.map').addClass('lights');
                  $('#Lakes').attr('class', 'lights');
               }, 2000);
               //                     setTimeout(function(){
               $('#pulse').attr('class', '');
               //                     }, 3000);

               setTimeout(function () {

                  var npLeft = $("#new-province").offset().left;
                  var npRight = $("#new-province").offset().left + $("#new-province").outerWidth();
                  var npBottom = $("#new-province").offset().top + 10;
                  var npCenter = npLeft + 20;
                  if($(window).width() > 768) {

                  var mapLeft = $('.hidden-sm .map-zoom').offset().left;
                  var mapTop = $('.hidden-sm .map-zoom').offset().top;
                  var mapRight = mapLeft + $('.hidden-sm .map-zoom').outerWidth();
                  var mapBottom = mapTop + $('.hidden-sm .map-zoom').outerHeight();
                  } else {
                 var mapLeft = $('.visible-sm .map-zoom').offset().left;
                  var mapTop = $('.visible-sm .map-zoom').offset().top;
                  var mapRight = mapLeft + $('.visible-sm .map-zoom').outerWidth();
                  var mapBottom = mapTop + $('.visible-sm .map-zoom').outerHeight();
                  }

                  if($(window).width() > 768) {
                  $('#svg polygon').attr("points", "" + npCenter + "," + npBottom + " " + mapRight + "," + mapTop + " " + mapLeft + "," + mapBottom + "");
                  $('#svg polygon, .location #pulse > *, .location #Lakes').css("transform-origin", "" + npCenter + "px " + npBottom + "px 0");

                  } else {

                  $('.map-over svg').css('max-height', $('.map-bg').outerHeight() + $('.visible-sm').outerHeight());
                  $('#svg polygon').attr("points", "" + (npLeft + 15) + "," + npBottom + " " + mapRight + "," + mapTop + " " + mapLeft + "," + mapTop + "");

                  }


                  $('#svg').attr('class', 'in');

               }, 3000);
               setTimeout(function () {
                  $('.map-zoom').addClass('in');


               }, 4500);

            });
            $(window).resize(function () {
                  var npLeft = $("#new-province").offset().left;
                  var npRight = $("#new-province").offset().left + $("#new-province").outerWidth();
                  var npBottom = $("#new-province").offset().top + 10;
                  var npCenter = npLeft + 20;
                 if($(window).width() > 768) {

                  var mapLeft = $('.hidden-sm .map-zoom').offset().left;
                  var mapTop = $('.hidden-sm .map-zoom').offset().top;
                  var mapRight = mapLeft + $('.hidden-sm .map-zoom').outerWidth();
                  var mapBottom = mapTop + $('.hidden-sm .map-zoom').outerHeight();
                  } else {
                 var mapLeft = $('.visible-sm .map-zoom').offset().left;
                  var mapTop = $('.visible-sm .map-zoom').offset().top;
                  var mapRight = mapLeft + $('.visible-sm .map-zoom').outerWidth();
                  var mapBottom = mapTop + $('.visible-sm .map-zoom').outerHeight();
                  }

                  if($(window).width() > 768) {
                  $('#svg polygon').attr("points", "" + npCenter + "," + npBottom + " " + mapRight + "," + mapTop + " " + mapLeft + "," + mapBottom + "");

                  } else {
                  $('.map-over svg').css('max-height', $('.map-bg').outerHeight() + $('.visible-sm').outerHeight());
                  $('#svg polygon').attr("points", "" + (npLeft + 15) + "," + npBottom + " " + mapRight + "," + mapTop + " " + mapLeft + "," + mapTop + "");

                  }

            });
         }
      }, // About us page, note the change from about-us to about_us.
      'clients': {
         init: function () {
            // JavaScript to be fired on the about us page
         },
         finalize: function () {
            // JavaScript to be fired on the home page, after the init JS

            /*!
             * Salvattore 1.0.9 by @rnmp and @ppold
             * https://github.com/rnmp/salvattore
             */
            ! function (e, t) {
               "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? module.exports = t() : e.salvattore = t()
            }(this, function () { /*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas, David Knight. Dual MIT/BSD license */
               window.matchMedia || (window.matchMedia = function () {
                     "use strict";
                     var e = window.styleMedia || window.media;
                     if (!e) {
                        var t = document.createElement("style"),
                           n = document.getElementsByTagName("script")[0],
                           r = null;
                        t.type = "text/css", t.id = "matchmediajs-test", n.parentNode.insertBefore(t, n), r = "getComputedStyle" in window && window.getComputedStyle(t, null) || t.currentStyle, e = {
                           matchMedium: function (e) {
                              var n = "@media " + e + "{ #matchmediajs-test { width: 1px; } }";
                              return t.styleSheet ? t.styleSheet.cssText = n : t.textContent = n, "1px" === r.width
                           }
                        }
                     }
                     return function (t) {
                        return {
                           matches: e.matchMedium(t || "all"),
                           media: t || "all"
                        }
                     }
                  }()), /*! matchMedia() polyfill addListener/removeListener extension. Author & copyright (c) 2012: Scott Jehl. Dual MIT/BSD license */
                  function () {
                     "use strict";
                     if (window.matchMedia && window.matchMedia("all").addListener) return !1;
                     var e = window.matchMedia,
                        t = e("only all").matches,
                        n = !1,
                        r = 0,
                        a = [],
                        i = function (t) {
                           clearTimeout(r), r = setTimeout(function () {
                              for (var t = 0, n = a.length; n > t; t++) {
                                 var r = a[t].mql,
                                    i = a[t].listeners || [],
                                    o = e(r.media).matches;
                                 if (o !== r.matches) {
                                    r.matches = o;
                                    for (var c = 0, l = i.length; l > c; c++) i[c].call(window, r)
                                 }
                              }
                           }, 30)
                        };
                     window.matchMedia = function (r) {
                        var o = e(r),
                           c = [],
                           l = 0;
                        return o.addListener = function (e) {
                           t && (n || (n = !0, window.addEventListener("resize", i, !0)), 0 === l && (l = a.push({
                              mql: o,
                              listeners: c
                           })), c.push(e))
                        }, o.removeListener = function (e) {
                           for (var t = 0, n = c.length; n > t; t++) c[t] === e && c.splice(t, 1)
                        }, o
                     }
                  }(),
                  function () {
                     "use strict";
                     for (var e = 0, t = ["ms", "moz", "webkit", "o"], n = 0; n < t.length && !window.requestAnimationFrame; ++n) window.requestAnimationFrame = window[t[n] + "RequestAnimationFrame"], window.cancelAnimationFrame = window[t[n] + "CancelAnimationFrame"] || window[t[n] + "CancelRequestAnimationFrame"];
                     window.requestAnimationFrame || (window.requestAnimationFrame = function (t, n) {
                        var r = (new Date).getTime(),
                           a = Math.max(0, 16 - (r - e)),
                           i = window.setTimeout(function () {
                              t(r + a)
                           }, a);
                        return e = r + a, i
                     }), window.cancelAnimationFrame || (window.cancelAnimationFrame = function (e) {
                        clearTimeout(e)
                     })
                  }(), "function" != typeof window.CustomEvent && ! function () {
                     "use strict";

                     function e(e, t) {
                        t = t || {
                           bubbles: !1,
                           cancelable: !1,
                           detail: void 0
                        };
                        var n = document.createEvent("CustomEvent");
                        return n.initCustomEvent(e, t.bubbles, t.cancelable, t.detail), n
                     }
                     e.prototype = window.Event.prototype, window.CustomEvent = e
                  }();
               var e = function (e, t, n) {
                  "use strict";
                  var r = {},
                     a = [],
                     i = [],
                     o = [],
                     c = function (e, t, n) {
                        e.dataset ? e.dataset[t] = n : e.setAttribute("data-" + t, n)
                     };
                  return r.obtainGridSettings = function (t) {
                     var n = e.getComputedStyle(t, ":before"),
                        r = n.getPropertyValue("content").slice(1, -1),
                        a = r.match(/^\s*(\d+)(?:\s?\.(.+))?\s*$/),
                        i = 1,
                        o = [];
                     return a ? (i = a[1], o = a[2], o = o ? o.split(".") : ["column"]) : (a = r.match(/^\s*\.(.+)\s+(\d+)\s*$/), a && (o = a[1], i = a[2], i && (i = i.split(".")))), {
                        numberOfColumns: i,
                        columnClasses: o
                     }
                  }, r.addColumns = function (e, n) {
                     for (var a, i = r.obtainGridSettings(e), o = i.numberOfColumns, l = i.columnClasses, s = new Array(+o), u = t.createDocumentFragment(), d = o; 0 !== d--;) a = "[data-columns] > *:nth-child(" + o + "n-" + d + ")", s.push(n.querySelectorAll(a));
                     s.forEach(function (e) {
                        var n = t.createElement("div"),
                           r = t.createDocumentFragment();
                        n.className = l.join(" "), Array.prototype.forEach.call(e, function (e) {
                           r.appendChild(e)
                        }), n.appendChild(r), u.appendChild(n)
                     }), e.appendChild(u), c(e, "columns", o)
                  }, r.removeColumns = function (n) {
                     var r = t.createRange();
                     r.selectNodeContents(n);
                     var a = Array.prototype.filter.call(r.extractContents().childNodes, function (t) {
                           return t instanceof e.HTMLElement
                        }),
                        i = a.length,
                        o = a[0].childNodes.length,
                        l = new Array(o * i);
                     Array.prototype.forEach.call(a, function (e, t) {
                        Array.prototype.forEach.call(e.children, function (e, n) {
                           l[n * i + t] = e
                        })
                     });
                     var s = t.createElement("div");
                     return c(s, "columns", 0), l.filter(function (e) {
                        return !!e
                     }).forEach(function (e) {
                        s.appendChild(e)
                     }), s
                  }, r.recreateColumns = function (t) {
                     e.requestAnimationFrame(function () {
                        r.addColumns(t, r.removeColumns(t));
                        var e = new CustomEvent("columnsChange");
                        t.dispatchEvent(e)
                     })
                  }, r.mediaQueryChange = function (e) {
                     e.matches && Array.prototype.forEach.call(a, r.recreateColumns)
                  }, r.getCSSRules = function (e) {
                     var t;
                     try {
                        t = e.sheet.cssRules || e.sheet.rules
                     } catch (n) {
                        return []
                     }
                     return t || []
                  }, r.getStylesheets = function () {
                     var e = Array.prototype.slice.call(t.querySelectorAll("style"));
                     return e.forEach(function (t, n) {
                        "text/css" !== t.type && "" !== t.type && e.splice(n, 1)
                     }), Array.prototype.concat.call(e, Array.prototype.slice.call(t.querySelectorAll("link[rel='stylesheet']")))
                  }, r.mediaRuleHasColumnsSelector = function (e) {
                     var t, n;
                     try {
                        t = e.length
                     } catch (r) {
                        t = 0
                     }
                     for (; t--;)
                        if (n = e[t], n.selectorText && n.selectorText.match(/\[data-columns\](.*)::?before$/)) return !0;
                     return !1
                  }, r.scanMediaQueries = function () {
                     var t = [];
                     if (e.matchMedia) {
                        r.getStylesheets().forEach(function (e) {
                           Array.prototype.forEach.call(r.getCSSRules(e), function (e) {
                              try {
                                 e.media && e.cssRules && r.mediaRuleHasColumnsSelector(e.cssRules) && t.push(e)
                              } catch (n) {}
                           })
                        });
                        var n = i.filter(function (e) {
                           return -1 === t.indexOf(e)
                        });
                        o.filter(function (e) {
                           return -1 !== n.indexOf(e.rule)
                        }).forEach(function (e) {
                           e.mql.removeListener(r.mediaQueryChange)
                        }), o = o.filter(function (e) {
                           return -1 === n.indexOf(e.rule)
                        }), t.filter(function (e) {
                           return -1 == i.indexOf(e)
                        }).forEach(function (t) {
                           var n = e.matchMedia(t.media.mediaText);
                           n.addListener(r.mediaQueryChange), o.push({
                              rule: t,
                              mql: n
                           })
                        }), i.length = 0, i = t
                     }
                  }, r.rescanMediaQueries = function () {
                     r.scanMediaQueries(), Array.prototype.forEach.call(a, r.recreateColumns)
                  }, r.nextElementColumnIndex = function (e, t) {
                     var n, r, a, i = e.children,
                        o = i.length,
                        c = 0,
                        l = 0;
                     for (a = 0; o > a; a++) n = i[a], r = n.children.length + (t[a].children || t[a].childNodes).length, 0 === c && (c = r), c > r && (l = a, c = r);
                     return l
                  }, r.createFragmentsList = function (e) {
                     for (var n = new Array(e), r = 0; r !== e;) n[r] = t.createDocumentFragment(), r++;
                     return n
                  }, r.appendElements = function (e, t) {
                     var n = e.children,
                        a = n.length,
                        i = r.createFragmentsList(a);
                     Array.prototype.forEach.call(t, function (t) {
                        var n = r.nextElementColumnIndex(e, i);
                        i[n].appendChild(t)
                     }), Array.prototype.forEach.call(n, function (e, t) {
                        e.appendChild(i[t])
                     })
                  }, r.prependElements = function (e, n) {
                     var a = e.children,
                        i = a.length,
                        o = r.createFragmentsList(i),
                        c = i - 1;
                     n.forEach(function (e) {
                        var t = o[c];
                        t.insertBefore(e, t.firstChild), 0 === c ? c = i - 1 : c--
                     }), Array.prototype.forEach.call(a, function (e, t) {
                        e.insertBefore(o[t], e.firstChild)
                     });
                     for (var l = t.createDocumentFragment(), s = n.length % i; 0 !== s--;) l.appendChild(e.lastChild);
                     e.insertBefore(l, e.firstChild)
                  }, r.registerGrid = function (n) {
                     if ("none" !== e.getComputedStyle(n).display) {
                        var i = t.createRange();
                        i.selectNodeContents(n);
                        var o = t.createElement("div");
                        o.appendChild(i.extractContents()), c(o, "columns", 0), r.addColumns(n, o), a.push(n)
                     }
                  }, r.init = function () {
                     var e = t.createElement("style");
                     e.innerHTML = "[data-columns]::before{display:block;visibility:hidden;position:absolute;font-size:1px;}", t.head.appendChild(e);
                     var n = t.querySelectorAll("[data-columns]");
                     Array.prototype.forEach.call(n, r.registerGrid), r.scanMediaQueries()
                  }, r.init(), {
                     appendElements: r.appendElements,
                     prependElements: r.prependElements,
                     registerGrid: r.registerGrid,
                     recreateColumns: r.recreateColumns,
                     rescanMediaQueries: r.rescanMediaQueries,
                     init: r.init,
                     append_elements: r.appendElements,
                     prepend_elements: r.prependElements,
                     register_grid: r.registerGrid,
                     recreate_columns: r.recreateColumns,
                     rescan_media_queries: r.rescanMediaQueries
                  }
               }(window, window.document);
               return e
            });

         }
      }
   };
   // The routing fires all common scripts, followed by the page specific scripts.
   // Add additional events for more control over timing e.g. a finalize event
   var UTIL = {
      fire: function (func, funcname, args) {
         var fire;
         var namespace = Sage;
         funcname = (funcname === undefined) ? 'init' : funcname;
         fire = func !== '';
         fire = fire && namespace[func];
         fire = fire && typeof namespace[func][funcname] === 'function';
         if (fire) {
            namespace[func][funcname](args);
         }
      },
      loadEvents: function () {
         // Fire common init JS
         UTIL.fire('common');
         // Fire page-specific init JS, and then finalize JS
         $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
         });
         // Fire common finalize JS
         UTIL.fire('common', 'finalize');
      }
   };
   // Load Events
   $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
